// @flow
import React, {type Node} from 'react';

import {Analytics, AnalyticsProvider, NextPageViewTracker} from 'analytics';
import {GoogleMapsAPI, GoogleMapsAPIProvider} from 'google/maps';
import config from 'config';
import {MuiThemeProvider, CssBaseline} from 'ui';
import {ErrorsProvider, ErrorDialog, type ErrorDetails} from 'errors';
import {theme} from 'theme/v2';
import {WebService} from 'pb/cwn/web';
import {EnvProvider} from 'env';
import {LoggerProvider} from 'log';
import {WebLogger} from 'web';
import {TechServiceContext} from 'tech';
import {LinkContext} from 'link';
import NextLink from 'link/NextLink';
import {TechService} from 'pb/cwn/tech/server';

const dev = process.env.NODE_ENV === 'development';

// $ExpectError missing 'browser' in process object typedef
if (process.env.NODE_ENV === 'development' && process.browser) {
  const proxyCookie = config.productionProxyWebSacsid;
  if (proxyCookie && proxyCookie.length) {
    document.cookie = `SACSID=${proxyCookie}; path=/;`;
  } else {
    document.cookie = `SACSID=; Max-Age=-99999999;`;
  }
}

const proxyEnabled = process.env.REACT_APP_PRODUCTION_PROXY === 'true' && dev;
const browser = typeof window !== 'undefined';
const env = {testing: false};

let webPrefix = '/';
if (dev) {
  webPrefix = '/devTech/';
}
if (proxyEnabled) {
  webPrefix = '/productionTech/';
}

const techService = new TechService(`${webPrefix}_techservice`);
const logger = new WebLogger(new WebService(`${webPrefix}v2`));

// must handle client and server-side rendering
const analytics = browser
  ? new Analytics({gaTrackingID: config.gaTrackingID, basename: '/'})
  : (null: any);

const googleMapsAPI = browser ? new GoogleMapsAPI() : (null: any);

type Props = {
  children: Node,
};

// must handle client and server-side rendering
function Provider({children}: Props) {
  return (
    <EnvProvider env={env}>
      <LoggerProvider logger={logger}>
        <AnalyticsProvider analytics={analytics}>
          <GoogleMapsAPIProvider googleMapsAPI={googleMapsAPI}>
            <TechServiceContext.Provider value={techService}>
              <MuiThemeProvider theme={theme}>
                <NextPageViewTracker>
                  <ErrorsProvider
                    onError={handleError}
                    renderErrorDialog={renderErrorDialog}>
                    <LinkContext.Provider value={NextLink}>
                      <CssBaseline />
                      {children}
                    </LinkContext.Provider>
                  </ErrorsProvider>
                </NextPageViewTracker>
              </MuiThemeProvider>
            </TechServiceContext.Provider>
          </GoogleMapsAPIProvider>
        </AnalyticsProvider>
      </LoggerProvider>
    </EnvProvider>
  );
}

function handleError({error, description}: ErrorDetails) {
  logger.error(
    error.message,
    typeof error.stack !== 'undefined' ? error.stack : undefined,
  );

  if (browser) {
    analytics.exception({
      description,
      message: error.message,
      fatal: true,
    });
  }
}

function renderErrorDialog(open: boolean, onClose: () => void) {
  return <ErrorDialog open={open} onClose={onClose} />;
}

export default Provider;
