// @flow
import React from 'react';
import NextAppHead from 'next/head';
import {Head as NextDocumentHead} from 'next/document';

import {theme} from 'theme/v2';
import {getAssetURL} from 'nextjs';
import {fullstoryScript, gaScript, gtagScript} from 'analytics';

// static head tags
export function DocumentHead() {
  return (
    <NextDocumentHead>
      <meta charSet="utf-8" />
      <meta property="og:type" content="website" />
      <meta
        key="og:image"
        name="og:image"
        property="og:image"
        content={getAssetURL('/images/porch-social.jpg')}
      />
      <meta
        key="og:url"
        name="og:url"
        property="og:url"
        content="https://www.common.net/"
      />
      <link rel="shortcut icon" href={getAssetURL('/favicon.ico')} />
      {/* Use minimum-scale=1 to enable GPU rasterization */}
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
      />
      <meta name="theme-color" content="#000000" />
      {/* PWA primary color */}
      <meta name="theme-color" content={theme.palette.primary.main} />
      {/* fonts */}
      <link rel="stylesheet" href={getAssetURL('/fonts/fonts.css')} />
    </NextDocumentHead>
  );
}

// dynamic head tag defaults
// see: https://github.com/zeit/next.js/issues/9794
export function AppHead() {
  return (
    <NextAppHead>
      <title>
        Common Networks – Home internet with amazing speeds and honest values
      </title>
      <meta
        key="description"
        name="description"
        property="description"
        content="Common Networks | A new choice for super fast home internet."
      />
      <meta
        key="og:title"
        name="og:title"
        property="og:title"
        content="Common Networks – Home internet with amazing speeds and honest values"
      />
      <meta
        key="og:description"
        name="og:description"
        property="og:description"
        content="A new choice for super fast home internet"
      />
      <meta
        key="keywords"
        name="keywords"
        property="keywords"
        content="home internet, mesh network, common networks, bay area isp, affordable home internet, high-speed internet, internet, home broadband, alameda internet, santa clara internet, sunnyvale internet, wireless home internet, wisp"
      />
    </NextAppHead>
  );
}
