// @flow
import React from 'react';
import NextApp from 'next/app';

import {AppHead as SiteAppHead} from 'site/Head';
import {AppHead as TechAppHead} from 'tech/Head';
import SiteProvider from 'site/Provider';
import TechProvider from 'tech/Provider';

// see: https://github.com/mui-org/material-ui/tree/master/examples/nextjs
export default class App extends NextApp {
  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      const pe = jssStyles.parentElement;
      if (pe) {
        pe.removeChild(jssStyles);
      }
    }
  }

  render() {
    const {Component, pageProps, router} = this.props;

    if (router.pathname === '/tech' || router.pathname.startsWith('/tech/')) {
      return (
        <>
          <TechAppHead />
          <TechProvider>
            <Component {...pageProps} />
          </TechProvider>
        </>
      );
    }

    return (
      <>
        <SiteAppHead />
        <SiteProvider>
          <Component {...pageProps} />
        </SiteProvider>
      </>
    );
  }
}
