// @flow
import React from 'react';
import NextAppHead from 'next/head';
import {Head as NextDocumentHead} from 'next/document';

import {theme} from 'theme/v2';
import {getAssetURL} from 'nextjs';
import {fullstoryScript, gaScript, gtagScript} from 'analytics';

// static head tags
export function DocumentHead() {
  return (
    <NextDocumentHead>
      <meta charSet="utf-8" />
      <link rel="shortcut icon" href={getAssetURL('/favicon.ico')} />
      {/* Use minimum-scale=1 to enable GPU rasterization */}
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
      />
      <meta name="theme-color" content="#000000" />
      {/* Fullstory */}
      <script dangerouslySetInnerHTML={{__html: fullstoryScript}} />
      {/* Global site tag (gtag.js) - Google Analytics */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-80988864-1"
      />
      <script dangerouslySetInnerHTML={{__html: gaScript}} />
      {/* Google Tag Manager */}
      <script dangerouslySetInnerHTML={{__html: gtagScript}} />
      {/* PWA primary color */}
      <meta name="theme-color" content={theme.palette.primary.main} />
      {/* fonts */}
      <link rel="stylesheet" href={getAssetURL('/fonts/fonts.css')} />
    </NextDocumentHead>
  );
}

// dynamic head tag defaults
// see: https://github.com/zeit/next.js/issues/9794
export function AppHead() {
  return (
    <NextAppHead>
      <title>Tech App</title>
      <meta
        key="description"
        name="description"
        property="description"
        content="Common Networks Tech App"
      />
    </NextAppHead>
  );
}
