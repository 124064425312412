// @flow
import React, {type Node} from 'react';

import {Analytics, AnalyticsProvider, NextPageViewTracker} from 'analytics';
import {GoogleMapsAPI, GoogleMapsAPIProvider} from 'google/maps';
import config from 'config';
import {FirebaseAuthController} from 'auth';
import {MuiThemeProvider, CssBaseline} from 'ui';
import {ErrorsProvider, ErrorDialog, type ErrorDetails} from 'errors';
import {theme} from 'theme/v2';
import {WebService} from 'pb/cwn/web';
import {WebServiceProvider} from 'gae';
import {EnvProvider} from 'env';
import {AuthProvider} from 'auth';
import {LoggerProvider} from 'log';
import {WebLogger} from 'web';
import {StoreProvider as PromoStateProvider} from 'store';

const dev = process.env.NODE_ENV === 'development';
const browser = typeof window !== 'undefined';
const env = {testing: false};
const webService = new WebService(dev ? '/web/v2' : '/v2');
const logger = new WebLogger(webService);

// must handle client and server-side rendering
const analytics = browser
  ? new Analytics({gaTrackingID: config.gaTrackingID, basename: '/'})
  : (null: any);

const googleMapsAPI = browser ? new GoogleMapsAPI() : (null: any);

const authController = browser
  ? new FirebaseAuthController(config.firebase)
  : (null: any);

const initialPromoState = {
  code: '',
  description: '',
  isPromoShown: false,
  isPromoValid: false,
  isPromoExpired: false,
};

type Props = {
  children: Node,
};

function Provider({children}: Props) {
  return (
    <EnvProvider env={env}>
      <LoggerProvider logger={logger}>
        <AnalyticsProvider analytics={analytics}>
          <GoogleMapsAPIProvider googleMapsAPI={googleMapsAPI}>
            <AuthProvider controller={authController}>
              <WebServiceProvider webService={webService}>
                <MuiThemeProvider theme={theme}>
                  <NextPageViewTracker>
                    <ErrorsProvider
                      onError={handleError}
                      renderErrorDialog={renderErrorDialog}>
                      <PromoStateProvider
                        reducer={promoStateReducer}
                        initialState={initialPromoState}>
                        <CssBaseline />
                        {children}
                      </PromoStateProvider>
                    </ErrorsProvider>
                  </NextPageViewTracker>
                </MuiThemeProvider>
              </WebServiceProvider>
            </AuthProvider>
          </GoogleMapsAPIProvider>
        </AnalyticsProvider>
      </LoggerProvider>
    </EnvProvider>
  );
}

function handleError({error, description}: ErrorDetails) {
  logger.error(
    error.message,
    typeof error.stack !== 'undefined' ? error.stack : undefined,
  );

  if (browser) {
    analytics.exception({
      description,
      message: error.message,
      fatal: true,
    });
  }
}

function renderErrorDialog(open: boolean, onClose: () => void) {
  return <ErrorDialog open={open} onClose={onClose} />;
}

function promoStateReducer(
  state: typeof initialPromoState,
  action: {
    type: 'showBanner' | 'hideBanner' | 'setPromo',
  } & typeof initialPromoState,
) {
  switch (action.type) {
    case 'showBanner':
      return {
        ...state,
        isPromoShown: true,
      };
    case 'hideBanner':
      return {
        ...state,
        isPromoShown: false,
      };
    case 'setPromo':
      return {
        ...state,
        code: action.code,
        description: action.description,
        isPromoValid: action.isPromoValid,
        isPromoExpired: action.isPromoExpired,
      };
    default:
      throw new Error('unhandled action type');
  }
}

export default Provider;
