// @flow
import debounce from 'lodash/debounce';
import stacktrace from 'stacktrace-js';

import {type Logger} from 'log';
import {type Severity} from 'pb/cwn/log';
import {WebService, LogRequest, LogRequest_Message} from 'pb/cwn/web';

export default class WebLogger implements Logger {
  webService: WebService;

  constructor(webService: WebService) {
    this.webService = webService;
  }

  serverMessageQueue: LogRequest_Message[] = [];

  flushServerMessageQueue = debounce(
    () => {
      if (!this.serverMessageQueue.length) {
        return;
      }

      const messages = this.serverMessageQueue;
      this.serverMessageQueue = [];
      this.webService.Log(new LogRequest({messages})).catch(console.error);
    },
    1000,
    {leading: true, maxWait: 5000},
  );

  pushServerMessage(severity: Severity, message: string, stack?: string): void {
    this.serverMessageQueue = [
      ...this.serverMessageQueue,
      new LogRequest_Message({severity, message, stack: stack || ''}),
    ];
    this.flushServerMessageQueue();
  }

  debug = (message: string): void => {
    // eslint-disable-next-line no-console
    console.debug(message);
    this.pushServerMessage('DEBUG', message);
  };

  info = (message: string): void => {
    // eslint-disable-next-line no-console
    console.info(message);
    this.pushServerMessage('INFO', message);
  };

  warn = (message: string): void => {
    console.warn(message);
    this.pushServerMessage('WARN', message);
  };

  error = (message: string, stack?: string): void => {
    console.error(stack || message);

    if (process.env.NODE_ENV !== 'production') {
      // dev - use stack traces as-is
      this.pushServerMessage('ERROR', message, stack);
      return;
    }

    // production - parse minified stack trace before sending to server
    const dummyErr = new Error(message);
    let firstFrameIndex;

    if (stack) {
      dummyErr.stack = stack;
      firstFrameIndex = 0;
    } else {
      // using new generated stack, so pop this call from it
      firstFrameIndex = 1;
    }

    getErrorReportingStackTrace(dummyErr, firstFrameIndex).then(parsedStack => {
      this.pushServerMessage('ERROR', message, parsedStack);
    });
  };
}

// formats a stack trace correctly for Google Cloud Error Reporting parsers
// inspired by https://github.com/GoogleCloudPlatform/stackdriver-errors-js/blob/master/stackdriver-errors.js#L144-L169
async function getErrorReportingStackTrace(
  error: Error,
  firstFrameIndex: number,
): Promise<string> {
  try {
    const stack = await stacktrace.fromError(error);
    const lines = [error.toString()];
    // reconstruct to a JS stackframe as expected by Error Reporting parsers.
    for (let i = firstFrameIndex; i < stack.length; i++) {
      // cannot use stack[i].source as it is not populated from source maps.
      lines.push(
        [
          '    at ',
          // if a function name is not available '<anonymous>' will be used.
          stack[i].getFunctionName() || '<anonymous>',
          ' (',
          stack[i]
            .getFileName()
            // unwind our custom "lib" module resolution
            .replace('webpack:///lib', '/src/lib')
            // remove any other webpack prefixes
            .replace('webpack://', ''),
          ':',
          stack[i].getLineNumber(),
          ':',
          stack[i].getColumnNumber(),
          ')',
        ].join(''),
      );
    }
    return lines.join('\n');
  } catch (e) {
    // failure to extract stacktrace
    return [
      'Error extracting stack trace: ',
      e,
      '\n',
      error.toString(),
      '\n',
      '    (',
      (error: any).file,
      ':',
      (error: any).line,
      ':',
      (error: any).column,
      ')',
    ].join('');
  }
}
