// @flow
import React from 'react';
import DefaultNextLink from 'next/link';

import {type LinkProps} from 'link';

// implementation of generic link component for use in <LinkContext.Provider />
export default function NextLink({href, as, children, ...props}: LinkProps) {
  return (
    <DefaultNextLink passHref href={href} as={as}>
      <a {...props}>{children}</a>
    </DefaultNextLink>
  );
}
